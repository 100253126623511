<template>
  <div id="app" style="width: 100vw;height: 100vh;">
    <el-menu
      :default-active="routerKey"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b">
      <el-menu-item index="/">活体辨识</el-menu-item>
      <el-menu-item index="/facialAnalysis">活体分析</el-menu-item>
      <el-menu-item index="/awaFacialAnalysis">aws人脸分析</el-menu-item>
      <el-menu-item index="/broadVision">旷视AI人脸分析</el-menu-item>
      <el-menu-item index="/broadVisionAll">旷视AI全身</el-menu-item>
      <el-menu-item index="/awsSdk">aws SDK活体识别</el-menu-item>
      <!-- <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
    </el-menu>
    <div style="height: calc(100vh - 61px);">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'App',
    data() {
      return {
        routerKey:this.$route.path
      }
    },
    methods: {
      handleSelect(value) {
        console.log('切换',value)
        this.routerKey = value
        this.$router.push(value)
      }
    }
  }
</script>
<style scoped>
/* #id {
  width: 100vw;
  height: calc(100vh - 61px);
} */
</style>
import axios from 'axios'
// import { Message, Loading } from 'element-ui'
// let loadingInstance = null //这里是loading
// import router from '@/router'
// import store from '@/store'
// import i18n from '../lang'

let baseURL = 'https://matchgo.test.api.cskm.net/api/v1'
// let baseURL = '/api'
const service = axios.create({
  baseURL: baseURL,
  timeout: 100000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
//   let tokenInfo = JSON.parse(localStorage.getItem('TOKEN_INFO'))
//   if (tokenInfo && tokenInfo.token) {
//     config.headers['Authorization'] = `${tokenInfo.token_type} ${tokenInfo.token}`
//   }

//   const language = localStorage.getItem('language')
//   const registerLanguage = store.state.user.register_language
//   // 如果是註冊接口的話 就傳註冊的語言
//   if (config.url == '/register/register') {
//     config.headers['lang'] = registerLanguage || language
//   } else {
//     config.headers['lang'] = language || userHooks.getBrowserLanguage()
//   }
  // loadingInstance = Loading.service({
  //   lock: true,
  //   text: '载入中...',
  //   spinner: 'el-icon-loading',
  //   background: 'rgba(0, 0, 0, 0.7)'
  // })
  return config
})

// 添加响应拦截器
service.interceptors.response.use(response => {
  // 关闭载入中动画
  // loadingInstance.close()

  return response.data
  // if ([200].includes(data.code)) {
  //   return data
  // } else if ([202].includes(data.code)) { // 登录失效 token失效
    // 清空數據庫
    // const USER_INFO = JSON.parse(localStorage.getItem('USER_INFO'))
    // if (USER_INFO) indexedDB.deleteDatabase(`${USER_INFO.im_user_id}.sqlite`)
    // // 清空儲存的信息
    // localStorage.removeItem('USER_INFO')
    // localStorage.removeItem('TOKEN_INFO')
    // // 語言重置
    // localStorage.setItem("language", userHooks.getBrowserLanguage());
    // i18n.locale = userHooks.getBrowserLanguage()
    // openIM.IMSDK.getLoginStatus().then(({ data }) => {
    //   if (data == 3) {
    //     openIM.IMLogout().then(res => {
    //       router.replace({
    //         path: '/'
    //       })
    //     }).catch(err => {
    //       router.replace({
    //         path: '/'
    //       })
    //       Message.error(i18n.t('error.error_imLogin10'))
    //     })
    //   }
    // })
    // Message({
    //   message: data.message || i18n.t('public.axiosError1'),
    //   type: 'error',
    //   duration: 5 * 1000
    // })
  // } else {
  //   Message({
  //     message: '测试',
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   return Promise.reject(new Error(data.message || 'Error'))
  // }
}, error => {
  // 关闭载入中动画
  // loadingInstance.close()
  // Message({
  //   message: error.message || i18n.t('public.axiosError2'),
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  return Promise.reject(error)
})


// /**
//  * post方法，对应post請求
//  * @param {String} url [請求的url地址]
//  * @param {Object} params [請求时携带的参数]
//  * @param {String} type [post请求类型 json form-date 默认json]
//  * @param {Object} headers [请求头参数]
//  */
//  export function post(url, params, type = 'json', headers) {
//   return new Promise((resolve, reject) => {
//     const data = type === 'json' ? params : QS.stringify(params)
//     Service.post(url, data, headers)
//       .then(res => {
//         resolve(res)
//       })
//       .catch(err => {
//         reject(err)
//       })
//   })
// }
export default service
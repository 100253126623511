import Vue from 'vue';
import App from './App.vue';
import router from './router'

import { DatetimePicker, ActionSheet, Swipe, SwipeItem,NoticeBar,Circle,Button  } from 'vant';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'vant/lib/index.css';
Vue.use(DatetimePicker);
Vue.use(ActionSheet);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NoticeBar);
Vue.use(Circle);
Vue.use(Button);
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'
import DetectingFaces from '@/views/detectingFaces.vue'
import FacialAnalysis from '@/views/facialAnalysis.vue'
import AwaFacialAnalysis from '@/views/awaFacialAnalysis.vue'
import BroadVision from '@/views/broadVision.vue'
import BroadVisionAll from '@/views/broadVisionAll.vue'
import AwsSdk from '@/views/awsSdk.vue'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: "hash",
  base: '/',
  routes:[
        {
          path: '/',
          component: DetectingFaces,
        },
        {
          path: '/facialAnalysis',
          component: FacialAnalysis
        },
        {
          path: '/awaFacialAnalysis',
          component: AwaFacialAnalysis
        },
        {
          path: '/broadVision',
          component: BroadVision
        },
        {
          path: '/broadVisionAll',
          component: BroadVisionAll
        },
        {
          path: '/awsSdk',
          component: AwsSdk
        },
    ]
})
export default router
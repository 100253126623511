<template>
  <div class="face-recognition-container">
    <div class="phone-frame">
      <video ref="video" class="video" autoplay></video>
      <div class="overlay">
        <div class="face-outline"></div>
        <div v-if="currentAction === 'blink' && !success && !failure" class="action-indicator">请眨眼 2秒内完成动作</div>
        <div v-else-if="currentAction === 'mouth' && !success && !failure" class="action-indicator">请张嘴 2秒内完成动作</div>
        <div v-else-if="success" class="success-indicator">&#10004; 成功</div>
        <div v-else-if="failure" class="failure-indicator">检测失败，请重试</div>
      </div>
    </div>
    <button v-if="btnStatus" class="button1" @click="startDetection">开始检测</button>
  </div>
</template>

<script>
import AWS from 'aws-sdk';
import vant from "vant";

export default {
  data() {
    return {
      currentAction: '', // 初始动作为眨眼
      blinkDetected: false,
      mouthOpenDetected: false,
      success: false,
      failure: false,
      videoWidth: 1920,
      videoHeight: 1080,
      previousFaceDetails: null,
      blinkTimeoutId: null,
      mouthTimeoutId: null,
      intervalId: null,
      startTime: null,
      btnStatus: true,
      deviceId: window.localStorage.getItem(`deviceId`) || "", //设备id
    };
  },
  mounted() {
    this.initCamera();
  },
  methods: {
    async initCamera() {
      try {
        let mediaOpts = {
            audio: false,
            video: true,
            video: {
              width: this.isMobile()? 1000: this.videoWidth,
              height: this.isMobile()? 1000: this.videoHeight,
              // width: 1000,
              // height: 1000,
              frameRate: {
                ideal: 100,
                max: 150
              } //最佳帧率
            }
          };
          if (this.isObjEmpty(this.deviceId)) {
            mediaOpts.video.facingMode = "user"; //前置摄像头
            // mediaOpts.video.facingMode = "environment"; //后置摄像头
          } else {
            mediaOpts.video.deviceId = this.deviceId;
          }
        const stream = await navigator.mediaDevices.getUserMedia(mediaOpts);
        this.$refs.video.srcObject = stream;
      } catch (error) {
        vant.Dialog.alert({
          title: '失败',
          message: `打开摄像头失败：${error}`,
          theme: 'round-button',
        })
        console.error('Error accessing camera:', error);
      }
    },
    isObjEmpty(obj) {
        return (
          obj === undefined ||
          obj === "undefined" ||
          obj == null ||
          obj === "" ||
          obj.length === 0 ||
          (typeof obj === "object" && Object.keys(obj).length === 0)
        );
      },
    startDetection() {
      this.btnStatus = false
      this.currentAction = 'blink'
      this.resetDetection();
      this.intervalId = setInterval(this.capture, 300); // 每秒捕获10帧
      this.startTime = new Date().getTime();
    },
    resetDetection() {
      this.currentAction = 'blink';
      this.blinkDetected = false;
      this.mouthOpenDetected = false;
      this.success = false;
      this.failure = false;
      this.previousFaceDetails = null;
    },
    async capture() {
      const video = this.$refs.video;
      const canvas = document.createElement('canvas');
      canvas.width = this.videoWidth;
      canvas.height = this.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, this.videoWidth, this.videoHeight);

      const imageData = canvas.toDataURL('image/jpeg');
      const base64Image = imageData.split(',')[1];

      this.detectFaces(base64Image);
    },
    async detectFaces(base64Image) {
      // 配置AWS凭证
      AWS.config.update({
        accessKeyId: 'AKIA5VIXBEL6TRNL74FG',
        secretAccessKey: '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',
        region: 'ap-northeast-1'
      });

      const rekognition = new AWS.Rekognition();

      const params = {
        Image: {
          Bytes: this.base64ToArrayBuffer(base64Image)
        },
        Attributes: ['ALL']
      };

      try {
        const response = await rekognition.detectFaces(params).promise();
        if (response.FaceDetails.length > 0) {
          const faceDetail = response.FaceDetails[0];
          this.analyzeFaceMovements(faceDetail);
          this.previousFaceDetails = faceDetail;
        } else {
          this.faceDetected = false;
        }
      } catch (error) {
        this.btnStatus = true
        console.error('Error detecting faces:', error);
      }
    },
    // 判断是否为手机
    isMobile() {
        return /Mobi|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      },
    analyzeFaceMovements(faceDetail) {
      // EyesOpen
      const eyesOpen = faceDetail.EyesOpen.Value
      const mouthOpen = faceDetail.MouthOpen.Value
      if (this.previousFaceDetails) {
        const previousEyesOpen = this.previousFaceDetails.EyesOpen.Value
        const previousMouthOpen = this.previousFaceDetails.MouthOpen.Value
        // 判断眨眼
        if (this.currentAction === 'blink') {
          if (eyesOpen != previousEyesOpen) {
            this.blinkDetected = true;
            this.currentAction = 'mouth';
            this.showActionMessage('眨眼检测成功，请张嘴');
            clearTimeout(this.blinkTimeoutId);
            this.blinkTimeoutId = setTimeout(() => {
              this.blinkDetected = false;
            }, 2000);
          }
        }
        // 判断张嘴
        if (this.currentAction === 'mouth') {
          if (mouthOpen != previousMouthOpen) {
            this.mouthOpenDetected = true;
            this.showActionMessage('张嘴检测成功');
            clearTimeout(this.mouthTimeoutId);
            this.mouthTimeoutId = setTimeout(() => {
              this.mouthOpenDetected = false;
            }, 2000);
          }
        }
      }
      // 检查是否成功
      if (this.blinkDetected && this.mouthOpenDetected) {
        this.success = true;
        clearInterval(this.intervalId);
        this.showActionMessage('检测成功', true);
        this.btnStatus = true
        return;
      }
      // 检查是否失败
      const currentTime = new Date().getTime();
      if (currentTime - this.startTime > 4000) { // 超过4秒未成功
        this.failure = true;
        clearInterval(this.intervalId);
        this.showActionMessage('检测失败，请重试', false);
        this.btnStatus = true
      }
},
showActionMessage(message, isSuccess = false) {
  if (isSuccess) {
    this.successMessage = message;
    setTimeout(() => {
      this.successMessage = '';
    }, 3000);
  } else {
    this.failureMessage = message;
    setTimeout(() => {
      this.failureMessage = '';
    }, 3000);
  }
},

    base64ToArrayBuffer(base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }
  },
  beforeDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.blinkTimeoutId) {
      clearTimeout(this.blinkTimeoutId);
    }
    if (this.mouthTimeoutId) {
      clearTimeout(this.mouthTimeoutId);
    }
  }
};
</script>

<style scoped>
.face-recognition-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  /* height: 100vh; */
}
.button1 {
  position: absolute;
  bottom: 20px;
  padding: 10px;
  background-color: #409eff;
  color: #fff;
  border: none;
  border-radius: 15px;
  &:hover {
    cursor: pointer;
  }
}

.phone-frame {
  position: relative;
  width: 100%;
  height: 100%;
  /* border: 10px solid black; */
  /* border-radius: 30px; */
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.face-outline {
  position: absolute;
  /* width: 80%;
  height: 60%; */
  width: 500px;
  height: 600px;
  border: 2px solid white;
  border-radius: 50%;
}

.action-indicator,
.success-indicator,
.failure-indicator {
  position: absolute;
  top: 20px;
  background-color: rgba(0, 255, 0, 0.7);
  border-radius: 15px;
  padding: 10px;
  font-size: 18px;
  color: white;
  margin: 5px;
}

.action-indicator {
  background-color: #fff;
  color: #A66D79;
}

.success-indicator {
  background-color: rgba(0, 255, 0, 0.7);
}

.failure-indicator {
  background-color: rgba(255, 0, 0, 0.7);
}
@media screen and (max-width: 500px){
  .face-outline {
    position: absolute;
    width: 80%;
    height: 60%;
    /* width: 500px;
    height: 600px; */
    border: 2px solid white;
    border-radius: 50%;
  }
}
</style>

<template>
    <!-- 人脸分析 -->
      <div class="app" v-cloak>
        <div class="canvas-wrapper">
            <video
              ref="video"
              width="100%"
              height="100%"
              webkit-playsinline="true"
              playsinline="true"
              preload="auto"
              autoplay
              loop
              muted
            ></video>
            <canvas ref="canvas" width="100%" height="100%"></canvas>
            <!-- <img src="@/assets/humanoid.jpeg" alt="" class="humanoid"> -->
            <div v-if="detectedData.genderName && promptStatus" class="information" v-loading="detectedStatus">
                {{detectedData.genderName}}/{{detectedData.age}}岁/魅力指数{{detectedData.beauty}}/认证等级 {{detectedData.attestation}}
            </div>
            <div class="prompt" v-if="promptStatus">{{promptTitle}}</div>
          </div>
          <!-- <img :src="sequenceImg"/> -->
      </div>
  </template>
  
  <script>
  import {getFaceidActionSequence,liveness,detectFace} from '@/api/index.js'; 
  import humanoid from '@/assets/humanoid.jpeg'
  import vant from "vant";
  export default {
    name: "App",
    data() {
      return {
        width: 280,
        height: 280,
        actionShow: false,
        deviceList: [], //设备列表
        deviceId: window.localStorage.getItem(`deviceId`) || "", //设备id
        isCameraOpen: false, //相机是否打开
        inProgress: false, //是否正在录制
        mediaRecorder: null, //录制器
        mediaStreamTrack: null, //音频或视频流
        blob: null, //录制的视频
        ModelLoading: false, //模型加载中
        detector: null, //识别模型
        rafId: null,
        flipHorizontal: false, //镜像
        faceNullFrequency: 0,
        isFarArr: [], //记录远近
        isOpenMouthArr: [], //张嘴
        isWinkArr: [], //眨眼
        isShakingHisHeadArr: [], //摇头
        actionList: [
        //   {
        //     name: "远一些",
        //     value: 0,
        //   },
        //   {
        //     name: "近一些",
        //     value: 1,
        //   },
        //   {
        //     name: "张嘴",
        //     value: 2,
        //   },
        //   {
        //     name: "眨眼",
        //     value: 3,
        //   },
        //   {
        //     name: "向左转头",
        //     value: 4,
        //   },
        //   {
        //     name: "向右转头",
        //     value: 5,
        //   },
        ], //动作列表
        currentAction: [], //当前动作
        currentRate: 0,
        nextactionTime: 0,
        txData: {
            1: 2, // 张嘴
            2: 3 // 眨眼
        },
        sequence: '', // 动作顺序
        sequenceImg: '', // 上传成功后 返回图片
        promptTitle:'' , // 提示
        promptStatus: false,
        // 防抖状态
        antiShakeState: false,
        // 检测数据
        detectedData:{},
        detectedStatus: false

      };
    },
    mounted() {
    //   const size =
    //     (document.documentElement.clientWidth || window.innerWidth) * 0.6;
    //   this.width = size > 280 ? 280 : size;
    //   this.height = size > 280 ? 280 : size;
    //     this.getFaceidAction()
      // 一堆兼容代码
      this.compatible();
      this.openUserMedia();
    },
    beforeDestroy() {
        this.mediaRecorder && this.mediaRecorder.stop();
        this.detector.dispose();
        this.detector = null
        this.mediaRecorder = null
    },
    methods: {
      // 循环提示
      async loopPrompt () {
        if (!this.antiShakeState) {
            this.antiShakeState = true
            this.promptTitle = '请保持不动5秒'
            await this.mediaRecorder1()
            setTimeout(()=>{
                // 四秒后暂停
                this.mediaRecorder && this.mediaRecorder.stop();
            },4000)
            setTimeout(()=>{
                // 6秒检测一次
                this.antiShakeState = false
            },6000)
        }
      },
      click1() {
        // window.location.href=`line://msg/text/${encodeURIComponent('www.baidu.com')}`
        window.location.href=`facebook://share?link=${encodeURIComponent('www.baidu.com')}`
      },
        // 获取 动作数据
    getFaceidAction() {
        getFaceidActionSequence().then(res=>{
            if (res.data && res.data.data) {
                this.sequence = res.data.data
                const arroy = res.data.data.split(',')
                // 进行顺序调整
                this.actionList = arroy.map(item=>{
                    return this.actionList.find(val=>val.value==this.txData[item]) 
                })
            }
        })
    },
      isObjEmpty(obj) {
        return (
          obj === undefined ||
          obj === "undefined" ||
          obj == null ||
          obj === "" ||
          obj.length === 0 ||
          (typeof obj === "object" && Object.keys(obj).length === 0)
        );
      },
      async neuAufnehmen() {
          // this.currentAction = []
          await this.getUserMedia();
          await this.createDetector();
        //   await this.startMediaRecorder();
      },
      //打开摄像头
      async openUserMedia() {
        const isOpen = await this.getUserMedia();
        if (isOpen.code == "ok") {
          await this.createDetector();
          vant.Toast(`摄像头已打开`);
          // this.triggerToggleFlipHorizontal()
        } else {
          vant.Dialog.alert({
            title: '失败',
            message: `打开摄像头失败：${isOpen.errMsg}`,
            theme: 'round-button',
          }).then(() => {
            location.replace(`${location.pathname}?s=${new Date().getTime()}`)
          });
          return;
        };
      },
      getUserMedia() {
        return new Promise(async resolve => {
          this.inProgress = false;
          this.isCameraOpen = false;
          this.blob = null;
          const toast = vant.Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '打开摄像头',
          });
          let mediaOpts = {
            audio: false,
            video: true,
            video: {
              width: this.width,
              height: this.height,
              frameRate: {
                ideal: 100,
                max: 150
              } //最佳帧率
            }
          };
          if (this.isObjEmpty(this.deviceId)) {
            mediaOpts.video.facingMode = "user"; //前置摄像头
            // mediaOpts.video.facingMode = "environment"; //后置摄像头
          } else {
            mediaOpts.video.deviceId = this.deviceId;
          }
          try {
            const stream = await navigator.mediaDevices.getUserMedia(mediaOpts);
            this.mediaStreamTrack = stream;
            //获取设备
            this.deviceList = await this.getDevice() || [];
            let video = this.$refs['video'];
            video.pause();
            video.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
            this.triggerToggleFlipHorizontal()
            if ("srcObject" in video) {
              video.srcObject = stream
            } else {
              video.src = window.URL && window.URL.createObjectURL(stream) || stream
            };
            video.play();
            video.onplay = () => {
              toast.clear();
              this.isCameraOpen = true;
              resolve({ code: `ok` });
            };
          } catch (error) {
            toast.clear();
            console.error(error);
            resolve({ errMsg: error })
          }
        })
      },
      startMediaRecorder() {
        return new Promise(async resolve => {
          if (typeof MediaRecorder === "function") {
            this.isFarArr = []; //记录远近
            this.isOpenMouthArr = []; //张嘴
            this.isWinkArr = []; //眨眼
            this.isShakingHisHeadArr = []; //摇头
            this.inProgress = false;
            const toast = vant.Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true,
              message: '准备录制',
            });
  
            this.mediaRecorder = new MediaRecorder(this.mediaStreamTrack, {
              audioBitsPerSecond: 0, //音频码率
              videoBitsPerSecond: 1000000 * 20, //视频码率 (数值越大视频越清晰)
              // mimeType: 'video/webm;codecs=h264' //视频编码格式
            });
            this.mediaRecorder.start();
            // this.mediaRecorder.resume();
            this.mediaRecorder.ondataavailable = e => {
              this.blob = new Blob([e.data], {
                'type': e.currentTarget.mimeType
              });
            }
            this.mediaRecorder.onerror = e => {
              this.inProgress = false;
              console.error(e)
              toast.clear();
              resolve({ errMsg: e });
            }
            this.mediaRecorder.onstart = e => {
              this.inProgress = true;
              console.log('开始1');
              toast.clear();
              vant.Toast(`开始录制`);
              resolve({ code: `ok` });
            }
            this.mediaRecorder.onresume = e => {
              this.inProgress = true;
              console.log('恢复');
              toast.clear();
              resolve({ code: `ok` });
            }
            this.mediaRecorder.onstop = e => {
              this.inProgress = false;
              const saveFile = vant.Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '保存视频中',
              });
              console.log('结束');
              const url = window.URL && window.URL.createObjectURL(this.blob);
              vant.Dialog.confirm({
                title: '录制完成',
                message: `
                                  <video src="${url}#t=0.01" style="display: block;width: 100%;" webkit-playsinline="true" playsinline="true" controls autoplay></video>
                                  <div class="van-cell van-cell--center">
                                      <div class="van-cell__title" style="text-align: left;">
                                          <span>文件大小</span>
                                      </div>
                                      <div class="van-cell__value">
                                          <span>${(this.blob.size / 1024).toFixed(2)}kb</span>
                                      </div>
                                  </div>
                              `,
                theme: 'round-button',
                className: 'videoDialog',
                confirmButtonText: '上传'
              })
                .then(() => {
                  const _this = this
                  // const videoBase64
                  const toast = vant.Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: '检测中...',
                  });
                  this.blobToDataURI(this.blob,function(result){
                    liveness({videoBase64:result,validateData:_this.sequence}).then(res=>{
                      if (res.code === 200) {
                        _this.sequenceImg = res?.data?.data?.bestFrameBase64 && `data:image/jpg;base64,${res?.data?.data?.bestFrameBase64}`
                        // vant.Toast(`检测成功！`);
                        // const toast1 = vant.Toast.loading({
                        //     duration: 2000, // 持续展示 toast
                        //     forbidClick: true,
                        //     message: '检测成功！',
                        //   });
                        //   setTimeout(()=>{
                        //     toast1.clear()
                        //   },2000)
                        vant.Notify({ type: 'success', message: '检测成功' })
                      } else {
                        vant.Notify({ type: 'warning', message: res.message })
                        // vant.Toast(`${res.message}`);
                        // const toast1 = vant.Toast.loading({
                        //     duration: 2000, // 持续展示 toast
                        //     forbidClick: true,
                        //     message: `${res.message}`,
                        //   });
                        //   setTimeout(()=>{
                        //     toast1.clear()
                        //   },2000)
                      }
                    }).finally(()=>{
                      toast.clear()
                    })
                  })
                }).catch(()=>{})
              saveFile.clear();
            }
  
          } else {
            vant.Dialog.alert({
              title: '失败',
              message: `录制失败：浏览器不支持new MediaRecorder方法`,
              theme: 'round-button',
            }).then(() => {
              // location.replace(`${location.pathname}?s=${new Date().getTime()}`)
            });
          }
        })
      },
      // blob 转 base64
      blobToDataURI(blob, callback) {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = function (e) {
          callback(e.target.result);
        };
      },
      async StopMediaRecorder() {
        this.inProgress = false;
        this.isCameraOpen = false;
        const toast = vant.Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: '已完成！保存视频中',
        });
        await this.delay(5); //所有动作结束以后 延迟五秒关闭录制。
        // this.mediaStreamTrack.getVideoTracks().forEach(track => {
        //   track.stop();
        // })
        this.mediaRecorder && this.mediaRecorder.stop();
        toast.clear();
      },
      //镜像切换
      triggerToggleFlipHorizontal() {
        this.flipHorizontal = !this.flipHorizontal;
        var video = this.$refs['video'];
        if (this.flipHorizontal) {
          video.style.transform = 'scale(-1, 1)';
        } else {
          video.style.transform = 'scale(1, 1)';
        };
        // this.createDetector();
      },
      //随机生成动作
      generateAction() {
        this.$refs.actionSwipe.swipeTo(0);
        this.currentRate = 0;
        // this.currentAction = this.actionList
        this.currentAction = this.actionList.map(e => {
          e.complete = false;
          e.time = 0;
          return e
        })
      },
      //显示当前动作
      showCurrentAction() {
        let name = '';
        const array = this.currentAction;
        for (let index = 0; index < array.length; index++) {
          const element = array[index];
          if (element.complete === false) {
            name = element.name;
            break;
          }
        }
        return name;
      },
      //触发动作
      triggerAction(actionValue) {
        if (this.isCameraOpen && this.inProgress) {
          let index = this.currentAction.findIndex(e => !e.complete);
          let findObj = this.currentAction.find(e => !e.complete) || {};
  
          let deffTime = new Date().getTime() - this.nextactionTime;
          //两个动作间隔3s
          if (findObj.value === actionValue && deffTime >= 3000) {
            this.nextactionTime = new Date().getTime();
            findObj.complete = true;
            this.$set(this.currentAction, index, findObj);
            const num = (this.currentAction.filter(e => e.complete)).length;
            this.currentRate = this.GetPercent(num, this.currentAction.length);
            this.$refs.actionSwipe.next();
          };
          let isComplete = this.currentAction.every(e => e.complete === true);
        //   if (isComplete) {
        //     this.StopMediaRecorder();
        //   }
        }
      },
      //创建识别模型
      async createDetector() {
        return new Promise(async resolve => {
        //   this.generateAction(); //生成模型
          const toast = vant.Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '加载模型中（首次加载需要1-2分钟）',
          });
          this.ModelLoading = true;
          try {
            if (!this.isObjEmpty(this.detector)) {
              this.detector.dispose();
            };
            if (!this.isObjEmpty(this.rafId)) {
              window.cancelAnimationFrame(this.rafId);
              this.rafId = null;
            };
            const model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
            const detectorConfig = {
              maxFaces: 1, //检测到的最大面部数量
              refineLandmarks: true, //可以完善眼睛和嘴唇周围的地标坐标，并在虹膜周围输出其他地标
              runtime: 'mediapipe',
              solutionPath: 'https://unpkg.com/@mediapipe/face_mesh', //WASM二进制文件和模型文件所在的路径
            };
            this.detector = await faceLandmarksDetection.createDetector(model, detectorConfig);
  
            toast.clear();
            this.rafId = window.requestAnimationFrame(this.renderPrediction);
            resolve({ code: `ok` });
          } catch (error) {
            toast.clear();
            this.ModelLoading = false;
            // vant.Dialog.alert({
            //     title: '失败',
            //     message: `创建识别模型失败-${error}`,
            //     theme: 'round-button',
            // }).then(() => {
            //     location.replace(`${location.pathname}?s=${new Date().getTime()}`)
            // });
            this.detector = null;
            // console.log(error);
            resolve({ errMsg: error });
          }
        })
      },
  
      //预测
      async renderPrediction() {
        var video = this.$refs['video'];
        var canvas = this.$refs['canvas'];
        if (!canvas) {
            vant.Toast(`没有检测到canvas`);
            return 
        }
        var context = canvas && canvas.getContext('2d');

        if (this.detector && this.isCameraOpen) {
          try {
            context.clearRect(0, 0, canvas.width, canvas.height);
            const Faces = await this.detector.estimateFaces(video, {
              flipHorizontal: this.flipHorizontal, //镜像
            });
  
            this.ModelLoading = false;
            if (Faces.length > 0) {
              this.promptStatus = true
              // vant.Toast(`检测到人脸`);
              this.loopPrompt()
              this.faceNullFrequency = 0;
              this.drawResults(Faces, context);
            } else {
              this.promptStatus = false
              this.faceNullFrequency++;
              //连续5帧没有检测到人脸提示
              if (this.faceNullFrequency >= 5) {
                vant.Toast(`没有检测到人脸`);
              }
            }
          } catch (error) {
            this.createDetector();
            this.ModelLoading = false;
            // console.log(error,'++++++++++');
            vant.Toast(`预测-${error}`);
            context.clearRect(0, 0, canvas.width, canvas.height);
          }
          this.rafId = window.requestAnimationFrame(this.renderPrediction);
        } else {
          vant.Toast(`没有检测到识别模型`);
          context.clearRect(0, 0, canvas.width, canvas.height);
          this.rafId = window.requestAnimationFrame(this.renderPrediction);
        }
      },
      //绘制
      drawResults(faces, ctx) {
        faces.forEach(faceItem => {
          // ctx.fillStyle = '#1af117';
          // (faceItem.keypoints || []).forEach((element, index) => {
          //   /* arc */
          //   ctx.beginPath();
          //   ctx.arc(element.x, element.y, 1, 0, 2 * Math.PI);
          //   ctx.fill();
          //   /* arc */
          // });
          const faceProportion = this.GetPercent(faceItem.box.width * faceItem.box.height, this.width * this.height);
        //   this.$refs['faceProportion'].innerHTML = `10-50之间,当前距离:<b>${Math.round(faceProportion)}</b>`;
  
          if (faceProportion <= 5) {
            vant.Toast(`距离太远`);
            return;
          };
          if (faceProportion >= 60) {
            vant.Toast(`距离太近`);
            return;
          };
        //   if (this.isCameraOpen && this.inProgress) {
        //     //靠近&远离
        //     this.isFarAndNear(faceItem);
  
        //     //张嘴
        //     this.isOpenMouth(faceItem, ctx);
  
        //     //眨眼
        //     this.isWink(faceItem, ctx);
  
        //     //摇头
        //     this.isShakingHisHead(faceItem, ctx);
        //   }
        });
      },
  
      //靠近&远离
      isFarAndNear(face) {
        const proportion = this.GetPercent(face.box.width * face.box.height, this.width * this.height);
        this.isFarArr.push(proportion);
        //计算4帧的动态变化
        if (this.isFarArr.length > 4) {
          this.isFarArr.shift();
          if (this.Increment(this.isFarArr) || this.Decrease(this.isFarArr)) {
            const first = this.isFarArr[0];
            const last = this.isFarArr[this.isFarArr.length - 1];
            const diff = this.GetPercent(first - last, first + last);
            if (diff <= -5) {
              // this.log(`【动作】靠近`, `info`);
              this.triggerAction(1);
            };
            if (diff >= 5) {
              // this.log(`【动作】远离`, `primary`);
              this.triggerAction(0);
            };
          }
        };
      },
      //张嘴
      isOpenMouth(face, ctx) {
  
        const featureIndex1 = [0, 17];
        const featureLocation1 = [];
  
        const featureIndex2 = [10, 152];
        const featureLocation2 = [];
  
        (face.keypoints || []).forEach((element, index) => {
          if (featureIndex1.includes(index)) {
            featureLocation1.push([element.x, element.y])
          }
          if (featureIndex2.includes(index)) {
            featureLocation2.push([element.x, element.y])
          }
        });
  
        // 10,152占0,17的比例
        const proportion = this.GetPercent(this.getDistance(
          featureLocation1[0][0],
          featureLocation1[0][1],
          featureLocation1[1][0],
          featureLocation1[1][1],
        ), this.getDistance(
          featureLocation2[0][0],
          featureLocation2[0][1],
          featureLocation2[1][0],
          featureLocation2[1][1],
        ));
        this.isOpenMouthArr.push(proportion);
  
        //计算2帧的动态变化
        if (this.isOpenMouthArr.length > 2) {
          this.isOpenMouthArr.shift();
          if (this.Increment(this.isOpenMouthArr)) {
            const first = this.isOpenMouthArr[0];
            const last = this.isOpenMouthArr[this.isOpenMouthArr.length - 1];
            const diff = this.GetPercent(first - last, first + last);
            if (diff <= -5) {
              // this.log(`【动作】张嘴`, `info`);
              this.triggerAction(2);
            };
          }
        }
  
      },
      //眨眼
      isWink(face, ctx) {
        // const leftEye = [159, 144];
        const leftEye = [160, 144];
        const leftEyeLocation = [];
        // const rightEye = [385, 374];
        const rightEye = [385, 374];
        const rightEyeLocation = [];
        (face.keypoints || []).forEach((element, index) => {
          if (leftEye.includes(index)) {
            leftEyeLocation.push([element.x, element.y]);
          }
          if (rightEye.includes(index)) {
            rightEyeLocation.push([element.x, element.y]);
          }
        });
        let leftProportion = this.getDistance(
          leftEyeLocation[0][0],
          leftEyeLocation[0][1],
          leftEyeLocation[1][0],
          leftEyeLocation[1][1],
        );
        let rightProportion = this.getDistance(
          rightEyeLocation[0][0],
          rightEyeLocation[0][1],
          rightEyeLocation[1][0],
          rightEyeLocation[1][1],
        );
  
  
  
        if (leftProportion <= 5 || rightProportion <= 5) {
          this.isWinkArr.push([leftProportion, rightProportion]);
          //连续4帧一次
          if (this.isWinkArr.length >= 4) {
            // this.log(`【动作】眨眼`, `info`);
            this.triggerAction(3);
            this.isWinkArr = [];
          }
        } else {
          this.isWinkArr = [];
        }
      },
      //isShakingHisHead
      isShakingHisHead(face, ctx) {
        const leftFace = [195, 93];
        const leftFaceLocation = [];
        const rightFace = [195, 323];
        const rightFaceLocation = [];
  
        (face.keypoints || []).forEach((element, index) => {
          if (leftFace.includes(index)) {
            leftFaceLocation.push([element.x, element.y]);
          }
          if (rightFace.includes(index)) {
            if (rightFaceLocation.length === 0) {
              ctx.moveTo(element.x, element.y)
            } else {
              ctx.lineTo(element.x, element.y)
            }
            rightFaceLocation.push([element.x, element.y]);
  
          }
        });
        let leftProportion = this.getDistance(
          leftFaceLocation[0][0],
          leftFaceLocation[0][1],
          leftFaceLocation[1][0],
          leftFaceLocation[1][1],
        );
        let rightProportion = this.getDistance(
          rightFaceLocation[0][0],
          rightFaceLocation[0][1],
          rightFaceLocation[1][0],
          rightFaceLocation[1][1],
        );
  
        const diff = this.GetPercent(leftProportion - rightProportion, leftProportion + rightProportion);
        this.isShakingHisHeadArr.push(diff); //左 -40 右 40
  
        //计算4帧的动态变化
        if (this.isShakingHisHeadArr.length > 4) {
          this.isShakingHisHeadArr.shift();
          const isL = this.isShakingHisHeadArr.every(e => e >= -60);
          const isR = this.isShakingHisHeadArr.every(e => e <= 60);
          if (isL) {
            // this.log(`【动作】向左转`, `info`);
            this.triggerAction(4);
          }
          if (isR) {
            // this.log(`【动作】向右转`, `info`);
            this.triggerAction(5);
          }
        };
        // console.log(diff);
      },
      actionSelect(value) {
        const { deviceId } = value;
        this.actionShow = false;
        if (!this.isObjEmpty(deviceId) && this.deviceId != deviceId) {
          this.deviceId = deviceId;
          window.localStorage.setItem(`deviceId`, deviceId);
          this.openUserMedia();
        }
  
      },
      //获取设备信息
      getDevice() {
        return new Promise(async resolve => {
          const toast = vant.Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '获取设备中',
          });
          try {
            const devicesList = await navigator.mediaDevices.enumerateDevices();
            const arr = [];
            (devicesList || []).forEach(e => {
              e.name = e.label || e.deviceId;
              if (e.kind === "videoinput" && e.deviceId && !e.name.includes('麦克风')) {
                e.color = e.deviceId == this.deviceId ? '#1989fa' : '#323233';
                arr.push(e)
              }
            });
            toast.clear();
            console.log(arr);
            resolve(arr);
          } catch (error) {
            toast.clear();
            console.log(error);
            resolve([]);
          };
        })
      },
      // 一堆兼容代码
      compatible() {
        window.URL = (window.URL || window.webkitURL || window.mozURL || window.msURL);
        if (navigator.mediaDevices === undefined) {
          navigator.mediaDevices = {};
        }
        if (navigator.mediaDevices.getUserMedia === undefined) {
          navigator.mediaDevices.getUserMedia = (constraints) => {
            var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
            if (!getUserMedia) {
              return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
            }
            return new Promise((resolve, reject) => {
              getUserMedia.call(navigator, constraints, resolve, reject);
            });
          }
        }
      },
      //递增
      Increment(array) {
        let is = true;
        for (let i = 0; i < array.length - 1; i++) {
          var n1 = array[i];
          var n2 = array[i + 1];
          if (n1 > n2) {
            is = false;
            break;
          }
        }
        return array.length > 1 ? is : false
      },
      //递减
      Decrease(array = []) {
        let is = true;
        for (let i = 0; i < array.length - 1; i++) {
          var n1 = array[i];
          var n2 = array[i + 1];
          if (n1 < n2) {
            is = false;
            break;
          }
        }
        return array.length > 1 ? is : false
      },
  
      //距离
      getDistance(x1, y1, x2, y2) {
        return Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2);
      },
      /**
       * @ 占比计算
       * @ num 当前数
       * @ total 总数
       */
      GetPercent(num, total) {
        num = parseFloat(num);
        total = parseFloat(total);
        if (isNaN(num) || isNaN(total)) {
          return "-";
        }
        return total <= 0 ? 0 : (Math.round(num / total * 10000) / 100.00);
      },
      /**
       * 毫秒数的可读格式
       */
      formatDuration(time) {
        let h = parseInt(time / 60 / 60 % 24)
        h = h < 10 ? '0' + h : h
        let m = parseInt(time / 60 % 60)
        m = m < 10 ? '0' + m : m
        let s = parseInt(time % 60)
        s = s < 10 ? '0' + s : s
        // 作为返回值返回
        return h === "00" ? [m, s].join(':') : [h, m, s].join(':')
      },
      //延迟
      delay(m = 1) {
        return new Promise(res => {
          setTimeout(() => {
            res(true);
          }, m * 1000);
        })
      },
      // 人脸属性分享
      getCommonDetectFace(imageBase64) {
        // imageBase64 ; imageUrl
        this.detectedStatus = true
        detectFace({imageBase64}).then(res=>{
            if (res.code == 200) {
                this.detectedData = res?.data?.data?.[0] || {}
                if (0<=this.detectedData.beauty && this.detectedData.beauty<=50) {
                    this.$set(this.detectedData,'attestation','完全认证')
                } else if (51<=this.detectedData.beauty && this.detectedData.beauty<=75) {
                    this.$set(this.detectedData,'attestation',this.detectedData.genderName=="男"?'霸总':'仙女')
                } else if(76<=this.detectedData.beauty && this.detectedData.beauty<=100) {
                    this.$set(this.detectedData,'attestation',this.detectedData.genderName=="男"?'富豪':'天后')
                }
                // console.log(this.detectedData,'+++++++')
            } else {
                this.detectedData = {}
                vant.Notify({ type: 'warning', message: res.message })
            }
        }).finally(()=>{
            this.detectedStatus = false
        })
      },
      mediaRecorder1() {
        return new Promise(async resolve => {
          if (typeof MediaRecorder === "function") {
  
            this.mediaRecorder = new MediaRecorder(this.mediaStreamTrack, {
              audioBitsPerSecond: 0, //音频码率
              videoBitsPerSecond: 1000000 * 20, //视频码率 (数值越大视频越清晰)
            });
            this.mediaRecorder.start();
            this.mediaRecorder.ondataavailable = e => {
              this.blob = new Blob([e.data], {
                'type': e.currentTarget.mimeType
              });
            }
            this.mediaRecorder.onerror = e => {
              this.inProgress = false;
              console.error(e)
              vant.Toast(`错误3${e}`);
              resolve({ errMsg: e });
            }
            this.mediaRecorder.onstart = e => {
              this.inProgress = true;
              console.log('开始1');
              resolve({ code: `ok` });
            }
            this.mediaRecorder.onresume = e => {
              this.inProgress = true;
              console.log('恢复');
              resolve({ code: `ok` });
            }
            const _this = this
            this.mediaRecorder.onstop = e => {
              // vant.Toast(`结束`);
              try {
                console.log('结束');
                const url = window.URL && window.URL.createObjectURL(this.blob);
                // var reader = new FileReader();
                // reader.readAsDataURL(this.blob);
                // reader.onload = function (e) {
                //   const url = e.target.result;

                // }
              //   创建视频元素
                const video = document.createElement("video");
                video.src = URL.createObjectURL(this.blob);
                video.crossOrigin = "anonymous"; // 确保跨域问题不会影响
                // vant.Toast(`执行`);
                video.onloadeddata = () => {
                  // vant.Toast(`执行1`);
                  try {
                    video.currentTime = 100; // 100幀 為第一畫面
                  } catch (error) {
                    vant.Toast(`错误5${error}`);
                  }
                }
                  video.onseeked = () => {
                    // vant.Toast(`执行2`);
                    try {
                      // 將
                      const canvas = document.createElement("canvas");
                      const context = canvas.getContext("2d");
                      canvas.width = video.videoWidth;
                      canvas.height = video.videoHeight;
                      context.drawImage(video, 0, 0, canvas.width, canvas.height);
                      const imageSrc = canvas.toDataURL("image/jpeg");
                      if (imageSrc) {
                        // vant.Toast(`存在图片`);
                      }
                      _this.sequenceImg = imageSrc
                      this.getCommonDetectFace(imageSrc)
                    } catch (error) {
                      vant.Toast(`错误1${error}`);
                    }
                  }
              } catch (error) {
                vant.Toast(`错误2${error}`);
              }
            }
  
          } else {
            vant.Dialog.alert({
              title: '失败',
              message: `录制失败：浏览器不支持new MediaRecorder方法`,
              theme: 'round-button',
            }).then(() => {
              // location.replace(`${location.pathname}?s=${new Date().getTime()}`)
            });
          }
        })
      }
    },
  };
  </script>
  <style scoped>
  * {
    padding: 0;
    margin: 0;
  }
  
  /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #f5f5f5;
  }
  
  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #c8c8c8;
  }
  
  [v-cloak] {
    opacity: 0 !important;
  }
  
  /* #app {
    margin: 0 auto;
    max-width: 800px;
  } */
  .app {
    width:100%;
    height: 100%;
  }
  .Camera-wrapper {
    width: 100%;
    height: 100%;
    margin: 1em auto;
    position: relative;
    overflow: hidden;
  }
  .van-circle {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .canvas-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 auto;
    /* border-radius: 50%; */
    overflow: hidden;
  }
  .canvas-wrapper .humanoid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .canvas-wrapper .information {
    position: absolute;
    z-index: 2;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    /* width: 200px; */
    background-color: #fff;
    border-radius: 50px;
    padding: 10px;
    color: #A66D79;
  }
  .canvas-wrapper .prompt {
    position: relative;
    z-index: 2;
    bottom: 128px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
  }
  .canvas-wrapper > video {
    background: #000;
    /* border-radius: 50%; */
    overflow: hidden;
  }
  
  .canvas-wrapper > canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* border-radius: 50%; */
    overflow: hidden;
  }
  
  .videoDialog .van-dialog__message {
    line-height: 1;
    white-space: unset;
  }
  .FlipHorizontal-toggle {
    right: 10vw;
  }
  .Camera-toggle {
    left: 10vw;
  }
  .FlipHorizontal-toggle,
  .Camera-toggle {
    position: fixed;
    bottom: 5vh;
    width: 15vw;
    height: 15vw;
    max-width: 50px;
    max-height: 50px;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20vw;
    -webkit-tap-highlight-color: transparent;
    z-index: 10;
    opacity: 0.8;
  }
  
  .FlipHorizontal-toggle > svg,
  .Camera-toggle > svg {
    width: 7vw;
    max-width: 30px;
    transition: 0.5s;
    transform: scaleX(1);
  }
  .FlipHorizontalIng > svg {
    transform: scaleX(-1);
  }
  .btn-operate {
    text-align: center;
    padding: 15px;
  }
  .btn-operate > button {
    min-width: 160px;
    border-radius: 5px;
  }
  .prompt-text {
    padding: 15px;
    color: #000;
    margin-top: 1em;
  }
  .prompt-text > h5 {
    opacity: 0.9;
    padding-bottom: 1em;
  }
  .prompt-text > ol {
    opacity: 0.7;
    list-style: revert;
    font-size: 13px;
    line-height: 1.7;
    padding-left: 1em;
  }
  .van-notice-bar {
    width: 60%;
    margin: 1em auto 0 auto;
    border-radius: 100px;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
  }
  .van-notice-bar .van-notice-bar__content,
  .van-notice-bar .van-swipe-item {
    width: 100%;
  }
  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
  </style>
  
import service from '@/utils/request'
export function getFaceidActionSequence(params) {
    return service({
        url: '/common/getFaceidActionSequence',
        method: 'get',
        params
    })
}
export function liveness(data) {
    return service({
        url: '/common/liveness',
        method: 'post',
        data
    })
}
export function detectBodyJoints(data) {
    return service({
        url: '/common/detectBodyJoints',
        method: 'post',
        data
    })
}
export function detectFace(data) {
    return service({
        url: '/common/detectFace',
        method: 'post',
        data
    })
}

// aws 人脸分析
export function detectAws(data) {
    return service({
        url: '/aws/detect',
        method: 'post',
        data
    })
}

// 旷视AI 
// 人脸检测和人脸分析
export function facePPDetect(data) {
    return service({
        url: '/facePP/detect',
        method: 'post',
        data
    })
}
// 人脸分析（通过人脸检测出多个人脸后单独分析）
export function analyzeDetect(data) {
    return service({
        url: '/facePP/analyze',
        method: 'post',
        data
    })
}
// 体检测
export function bodyDetectFacePP(data) {
    return service({
        url: '/facePP/bodyDetect',
        method: 'post',
        data
    })
}
// 人体骨骼点
export function facePPSkeleton(data) {
    return service({
        url: '/facePP/skeleton',
        method: 'post',
        data
    })
}